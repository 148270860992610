<template>
<div id="page">
	<div id="section_wrapper" class="works-section_wrapper" vertical>
		<!-- <div class="fi t-l">{{query}}</div> -->
		<section ref="works-start" class="head_works fl-l">
			<div class="pageh-he fl-co-c-l">
				<h1 class="hea-ht">{{ page.title }}</h1>
				<div class="til_sub fl-c" v-if="page.subtitle && page.subtitle != ''">{{ page.subtitle }}</div>
			</div>
		</section>
		<section class="item fl-l" v-for="item in items" :key="item.id" :ref="item.slug">
			<work-item v-bind="{ item }"></work-item>
		</section>
	</div>
</div>
</template>

<script>


import { mapGetters ,mapActions } from 'vuex'
import WorkItem from '@/components/work/WorkItem.vue'

export default {
	name: 'Works',
	components: {
		WorkItem
	},
	computed: {
		...mapGetters ("WorksStore", ['getWork']),
		...mapGetters("PagesStore", ['getPageInfo']),
		...mapGetters ("GeneralStore", ['getTouch','getPageIsGrabbing','getWindow']),
		items() { return this.getWork() },
		itemsLength() { return this.items.length },
		year() { return new Date().getYear() + 1900 },
		isGrabbing() { return this.getPageIsGrabbing },
		itemGap() { return this.getWindow.w / 10},
		page() {
            return this.getPageInfo('works')
        }
	},
	mounted() {
		this.setSections([this.$refs, this.$route.name])
	},
	methods: {
		...mapActions ("GeneralStore", ['setSections']),
	}
}
</script>