
<template>
    <router-link
        tag="div"
        class="item-wrapper fl-co-l"
        @mouseenter.native="itemHover($event, true); updateCursor(true)"
        @mouseleave.native="itemHover($event, false); updateCursor(false)"
        :to="{name: 'work', params: {slug: item.slug}}"
        exact>
        <div class="item_container fl ab wh h-c pe" ref="container" :class="{outline: item.outline}">
            <div class="item_content fl-c-l ab">
                <h2 class="item_name">{{ item.name }}</h2>
                <h3 class="item_brief">{{ item.brief }}</h3>
            </div>
            <div class="item_center fl-c t-l wh ab">
                <img v-if="getPoster" ref="img" class="item_image pe" :src="getPoster" :alt="item.name">
            </div>
            <div class="item_meta fl-b ab">
                <div class="item_type">{{ item.type }}</div>
                <div class="item_date">{{ item.date }}</div>
            </div>
        </div>
        <svg viewBox="0 0 20 9.2" xml:space="preserve" class="item_image-sz pe w1 z0"></svg>
    </router-link>
</template>

<script>
import gsap from "gsap"
import { mapGetters, mapActions } from 'vuex'



export default {
    name: 'WorkItem',
    props: {
        item: Object,
    },
    computed: {
        ...mapGetters("GeneralStore",['getTouch', 'getWindow', 'getMobile']),
        getPoster() { return `/assets/img/poster/${this.item.id}.jpg` }
    },
    methods: {
        ...mapActions ("GeneralStore", ['updateCursor']),
        itemHover(e, hover) {
            if(this.getMobile) return

            let img   = this.$refs.img
            let cont  = this.$refs.container

            gsap.killTweensOf(img,cont)

            if(hover) {
                gsap.to(cont, 0.5, { width:'95%', ease: 'power1.out' })
                gsap.to(img, 0.5, { scale: 1.1, ease: 'power1.out' })
            } else {
                gsap.to(cont, 0.5, { width:'100%', ease: 'power1.out' })
                gsap.to(img, 0.5, { scale: 1, ease: 'power1.out' })
            }
        }
    }
}

</script>